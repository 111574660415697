export const rules = {
  date: [
    { required: true, message: 'Пожалуйста укажите дату списания', trigger: 'blur' },
  ],
  warehouse_id: [
    { required: true, message: 'Пожалуйста укажите склад', trigger: 'blur' },
  ],
  sender_id: [
    { required: true, message: 'Пожалуйста укажите получателя', trigger: 'blur' },
  ],
  // receiver_id: [
  //   { required: true, message: 'Пожалуйста укажите получателя', trigger: 'blur' },
  // ],

};