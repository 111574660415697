export const ru_messages = {
  Dashboard: 'Dashboard',
  accaunt: "Аккаунт",
  access_right: "Права доступа",
  access_rights: "Права доступа",
  actions: "Действия",
  active: "Активный",
  active_patients: "Активные пациенты",
  add: "Добавить",
  add_branch: "Добавить отделение",
  add_bunk: "Добавить койку",
  add_class: "Добавить класс",
  add_conditions: "Добавить состояние",
  add_corps: "Добавить корпус",
  add_department_user: "Добавить пользователя отдела",
  add_departmentes: "Добавить отдел",
  add_document: "Добавить документ",
  add_drug_indicator: "Добавить назначение лекарства",
  add_drug_indicator_warehouse: "Добавить назначение лекарства (склад)",
  add_floor: "Добавить этаж",
  add_hospital: "Добавить госпитализацию",
  add_hospitalization_type: "Добавить тип госпитализации",
  add_medicine: "Добавить лекарства",
  add_movement: "Добавить перемещение",
  add_nationality: "Добавить национальность",
  add_new_bunk: "Добавить новую койку",
  add_new_department: "Добавить новый отдел",
  add_new_doctor_sign: "Добавить новую подпись",
  add_new_partner_clinic: "Добавить новую клинику",
  add_new_partner_doctor: "Добавить нового партнера доктора",
  add_new_patient: "Добавить нового пациента",
  add_new_service: "Добавить новую услугу",
  add_order_payment: "Добавить заказ платежей",
  add_partner_clinic: "Добавить партнера клиника",
  add_partner_doctor: "Добавить партнера доктора",
  add_patient_indicators: "Добавить показатели пациента",
  add_patient_physiotherapy: "Добавить физиотерапию пациенту",
  add_physiotherapy: "Добавить физиотерапию",
  add_processes: "Добавить Процесс",
  add_purpose_of_hospitalization: "Добавить цель госпитализации",
  add_region: "Добавить регион",
  add_requirement: "Добавить требование",
  add_room: "Добавить комнату",
  add_service: "Добавить услугу",
  add_to_reception: "Запись на прием",
  add_to_room: "Запись в палату",
  add_transaction: "Добавить транзакцию",
  add_type_of_injury: "Добавить вид травмы",
  add_unit_of_measure: "Добавить единицу измерения",
  address: "Адрес",
  address_patient: "Адрес пациента",
  address_register: "Адрес регистрации",
  adds: "Добавить",
  age: "Возраст",
  agent: "Агент",
  aggrement: "Договора",
  all_conclusions: "Все заключения",
  all_date: "Дата",
  all_laboratory_conclusions: "Лабораторные заключения",
  all_not_paid_price: "Общая сумма не оплачена",
  all_service: "Все услуги",
  all_service_patient: "Все услуги пациента",
  all_speciality: "Все специальности",
  allergy: "Аллергия",
  ambulatory: "Амбулаторная",
  amount: "Сумма",
  another_services: "Другие услуги",
  another_services_income: "Другие услуги сумма",
  archive: "Архив",
  arrival_date: "Дата прибытия",
  ask_about_write_off: "Спрашивать о списании?",
  authority: "Орган выдачи",
  auto_write_off: "Авто списание",
  available: "Доступно",
  back: "Возврат",
  balance: "Баланс",
  bank: "Банк",
  bank_account: "Расчетный счет",
  basic_information: "Основная информация",
  bed_schedules: "Расписание койек",
  blank_head: "Шапка от бланка",
  blok: "Блок",
  blood: "Группа крови",
  bonus: "Бонус",
  bonus_amount: "Бонус",
  bonus_amount_0: 'Сумма бонуса',
  bonus_date: "Дата бонус",
  bonus_debt: "Остаток",
  bonus_mode: "Режим создания бонуса",
  bonus_paid_amount: "Выплачено",
  bonuses: "Бонусы",
  booked: "Забронировано",
  born_place: "Место рождения",
  box_available: "Доступно в упаковке",
  box_quantity: 'Количество в упаковке',
  branch: "Отделение",
  branches: "Филиалы",
  bunk: "Койка",
  busy: "Занято",
  buy_ready_product: "Закупка продукции",
  c_sum: "сум",
  cabinat_balance: "Баланс клиента",
  cabinet: "Кабинет",
  cach: "Наличные",
  cancel: "Отмена",
  cannot_print: "Не может печататься",
  cargo_storage: "Хранение груза",
  cash: 'Зарплаты',
  cash_amount: 'Сумма зарплаты',
  cashbox: "Касса",
  cashbox_print: "Касса печать",
  category: "категория",
  category_mkb: "Категории МКБ",
  category_or_service: "Категория или услуга",
  category_services: "Категории услуг",
  change: "Заменить",
  change_branch: "Изменить отдел",
  change_write_off: "Изменение списания",
  choose_branch: "Выберите отделение",
  choose_category: "Выберите категорию",
  choose_owner_branch: "Выберите филиал",
  choose_status: "пожалуйста, выберите статус",
  choose_template: "Выбор шаблона",
  chose_a_speciality: "Выберите специальность",
  cities: "Города",
  citizenship: "Гражданство",
  city: "Город",
  class: "Класс",
  clear: "Очистить",
  clinic: "Клиника",
  clinic_diagnos: "Клинический диагноз",
  close: "Закрыть",
  closed_conclusions: "Утвержденных заключений",
  code: "Код",
  code_id: "Код ID",
  color: "Цвет",
  comment: "Комментарий",
  company_name: "Название компании",
  complated: "Выполнено",
  conResourcesText: "Под ресурсов",
  conclusion_date: "Дата заключения",
  conclusions: "Заключения",
  conclusions_history: "История заключений",
  condition: "Состояние",
  condition_patient: "Состояние пациента",
  conditions: "Cостояние",
  confirm: "Подтвердить",
  confirmed: "Подтверждено",
  consultation_services: "Консультация",
  consultation_services_income: "Консультация сумма",
  contact: "Контактное лицо",
  content: "Контент",
  contract_parent: "Гл. договор №",
  contract_parnet: "Гл. договор №",
  contract_payment: "Платежи по договору",
  contract_service: "Контактные услуги",
  contracts: "Договора",
  contracts_n: "Договора №",
  contractual_basis: "На договорной основе",
  corps: "Корпус",
  correspondent_account: "Кор. аккаунт",
  cost_amount: "Сумма себестоимости",
  cost_order: "Цена заказа",
  count: "Количество",
  countries: "Страны",
  country: "Страна",
  country_code: "Код страны",
  courier: "Курьер",
  couriers: "Курьеры",
  create: "Создать",
  create_citizenship: "Добавить гражданство",
  create_city: "Новый город",
  create_conclusion: "Создать заключение",
  create_condition: "Новое состояние",
  create_currency: "Новая валюта",
  create_hospitalization: "Добавить госпитализацию",
  create_new_order: "Создать новый заказ",
  create_new_patient: "Создать нового пациента",
  create_order_column: 'Наименование поля',
  create_organization: "Новая организация",
  created: "создано",
  created_at: "Дата создания",
  currency: "Валюта",
  current_price: "Действующая цена",
  daily: "За сегодня",
  dailyProcessReport: "Отчет по процессам",
  dailyTreatment: "Ежедневный показатель",
  dailyTreatment_patient: "Ежедневные показатели пациента",
  daily_hospital: "Дневной стационар",
  dapartment_user: "Пользователи отдела",
  dark: "Темный",
  date: "Дата создания",
  date_0: "Дата",
  date_aggrement: "Дата договора",
  date_hospitalization: "Дата госпитализации",
  date_injury: "Дата заболевания",
  date_movement: "Дата перемещения",
  date_of_issue: "Дата выпуска",
  date_produced: "Дата производства",
  date_receiving: "Дата получения",
  date_register: "Дата регистрации",
  date_requirement: "Дата требования",
  day: "День",
  day_hospital: "Дневной стационар",
  day_hospital_for_month: "Дневной стационар за месяц",
  day_hospital_for_today: "Дневной стационар сегодня",
  day_hospital_for_week: "Дневной стационар за неделю",
  day_hospital_income: "Дневной стационар сумма",
  day_in_warehouse: "Дней на складе",
  dds: "ДДС",
  deal_amount: "Сумма договора",
  debt_type: "Типы долгов",
  debtors: "Должники",
  debts: "Долги",
  defects: "Дефекты",
  delete: "Удалить",
  delete_calcel: "Удаление отменено",
  delete_notification: "Это навсегда удалит файл. Продолжить?",
  delete_notification_confirm: "Вы уверены, что хотите удалить его?",
  delete_setting: "Удалить",
  delete_success: "Удаление завершено",
  delivery_deadline: "Дата поставки",
  delivery_time: "Срок поставки",
  department: "Отдел",
  departure_date: "Дата отъезда",
  description: "Описание",
  detail: "Детали",
  diagnos: "Предварительный диагноз",
  direction: "Направление",
  disable: "Отключить",
  disabled: "Отключено",
  discount: "Скидка",
  discount_cost: "Скидочная цена",
  discount_percent: "Скидка %",
  discount_price: "Скидка (Сумм)",
  discounts: "Скидки",
  district: "Район",
  do_you_really_want_to_complete_the_hospitalization: 'Вы действительно хотите завершить госпитализацию?',
  do_you_really_want_to_finish: 'Вы действительно хотите Заканчивать',
  do_you_want: "Внимание Вы действительно хотите это сделать?",
  do_you_want_confirm: "Вы действительно хотите подтвердить ",
  dob: "Дата Рождения",
  doctor: "Врач",
  doctorProcessReport: "Общий отчет по процессам",
  doctorSign: "Подпись",
  doctorSigns: "Подписи",
  doctor_0: "Доктор",
  doctor_bonuses: "Бонусы врачей",
  doctor_can: "Покажитесь своему врачу",
  doctor_id: "ID доктора",
  doctor_name: "Доктор (фио)",
  doctor_service: "Услуги врача",
  doctors_settings: "Настройки врачей",
  document_forms_editor: "Редактировать формы документов",
  documents: "Документ",
  done_treatment: "Сделано (препарат успешно введен)",
  dont_have_file: "Нету файла",
  download: "Скачать",
  drive_license: "Водительское удостоверение",
  drug: "Лекарство",
  drug_bad_reaction: "Дефекты (После введения препарата, была реакция)",
  drug_good_reaction: "Далее (Реакция не от этого препарата продолжайте процедуру)",
  drug_indicator: "Назначение лекарства",
  drug_indicator_warehouse: "Назначение лекарства (склад)",
  drug_instruction: "Лекарство / Инструкция",
  duration: "Продолжительность",
  dynamic_photo: "Динамические обои",
  edit: "Изменить",
  email: "Электронная почта",
  email_address: "Email почта",
  employees_work_schedule: "График работы сотрудники",
  enable: "Включить",
  enabled: "Включено",
  endPeriod: "До числа",
  end_date: "до дата",
  end_period: "До",
  enter_id: "Введите идентификатор (№)",
  enumeration: "Перечисление",
  excelReport: "Отчет Excel",
  exemple: "Пример :",
  expence: "Расход",
  expences: "Расходы",
  expired: "Просроченные",
  express: "Экспресс",
  expresses: "Экспресс",
  fax: "Факс",
  female: "Женщина",
  file: "Файл",
  file_upload: "Загрузить файл",
  file_uploaded: "Файл загружен",
  filter: "Фильтры",
  filter_keyword: "Ключевое слово в фильтре",
  final_conclusion: "Выписка",
  fio: "ФИО",
  first_name: "Имя",
  flat: "Квартира",
  floor: "Этаж",
  flow_types: "Типы расхода",
  footer_header: "Нижний колонтитул",
  footer_image: "Нижнее изображение",
  for_bussiness: "Юридическое лицо",
  for_outsource: "Для аутсорса",
  for_personal: "Физичесикое лицо",
  form_template_and_signs: "Шаблоны формы и подписи",
  form_template_sign: "Подпись шаблона формы",
  forms: "Формы (МКБ-10)",
  forms_mkb: "Формы (МКБ-10)",
  forms_of_documents: "Формы документов",
  free: "Свободно",
  from: "От",
  from_hours: "От часов",
  from_time: "Начальное время",
  from_whom: "От кого",
  full_logo: "Полный логотип",
  full_name: "Полное наименование",
  gender: "Пол",
  gender_patients: "Пол пациентов",
  geography_settings: "Географические настройки",
  glass: "Стекла",
  go_back: "Назад",
  group: "Группа",
  group_of_patient: "Возрастные группы пациентов",
  groups: "Группы",
  half_logo: "Неполный логотип",
  half_yearly: "За пол года",
  height: "Рост",
  hidden: "Не указано",
  history: "История",
  history_transaction: "История транзакций",
  hospital: "Стационар",
  hospital_income: "Стационар сумма",
  hospital_patients_month: "Стационар пациентов за месяц",
  hospital_patients_today: "Стационар пациентов сегодня",
  hospital_patients_week: "Стационар пациентов за неделю",
  hospitalization: "Госпитализация",
  hospitalization_complated: "Госпитализация окончена",
  hospitalization_type: "Тип госпитализации",
  hour: "Часов",
  house: "Дом",
  how_many_days: "Сколько дней",
  how_prepare_medicine: "Как приготовить лекарства",
  id_code: "Идентификационный код",
  id_number: "ID заказа",
  ifo: "И.Ф.О",
  income_product: "Входящие продукты",
  incoming: "Входящие",
  indicator: "Показатель",
  indicators: "Показатели",
  info_aggrement: "Информация о договоре",
  info_treatment: "Информация для лечения",
  inn: "ИНН",
  input: "Введите",
  input_order_id_for_delete_status: "Удалить статус",
  instruction_for_use: "Инструкция по применению",
  insurance_company: 'Страховая компания',
  insurance_number: "Номер страховой",
  invalid: "Брак",
  invoice: "Счет-фактуры",
  invoice_insurance: "Счет-фактуры Страховых",
  invoices: "Счет-фактур",
  is_active: "Активен",
  is_close: "Заключение утверждено",
  is_conclusion: 'Заключение',
  is_register: 'Регистрация',
  is_report: 'Полный отчет',
  lab_nurse: "Забор анализов",
  laborant: "Лаборант",
  laboratory: "Лаборатория",
  lack_of: "Отсутствует",
  last_login: "Последний вход",
  lasting: "Длительность",
  legal_address: "Официальный адрес",
  lend: "В долг",
  letterhead: "Партнерская",
  life_time: "Срок",
  lifetime: "Срок",
  lifetime_services: "Срок услуги",
  light: "Свет",
  limit: "Лимит",
  load: "Загрузить",
  loading: "загрузка",
  locked: "Заблокировано",
  log_out: "Выйти",
  login: "Авторизация",
  logo: "Логотип",
  main: "Главная",
  main_warehouses: "Главные склады",
  male: "Мужчина",
  man: "Мужчина",
  manufactured_date: "дата изготовления",
  material: "Материал",
  materials_consuption: "Расход материала",
  materials_consuptions: "Расход материали",
  measurement: "Измерение",
  measurement_full: "Ед. измерения",
  measurements: "Ед. изм",
  medical_history: "История болезней",
  medicine: "Продукты",
  medicines: "Лекарственные средства",
  medicines_on: "Лекарства Н/О",
  men: "Мужчины",
  mfo: "МФО",
  minut: "мин.",
  money_per_day: "Цена за день",
  month: "Месяц",
  monthly: "За месяц",
  movement: "Перемещение",
  my_conclusions: "Мои заключения",
  my_informal_laboratory_patients: "Заказы лаборатория",
  my_informal_patients: "Заказы врачи",
  my_laboratory: "Лаборатория",
  my_office: "Мой Кабинет",
  my_patients: "Мои пациенты",
  name: "Наименование",
  name_0: "Название",
  name_service: "Название услуги",
  name_template: "Название шаблона",
  nationality: "Национальность",
  nds: "НДС",
  needed: "Необходимо",
  new: "Новый",
  new_aggrement: "Новый договор",
  new_category_service: "Новая категория услуги",
  new_country: "Новая страна",
  new_daily_process_report: "Новый отчет по процессам",
  new_discount: "Новый скидка",
  new_expence: "Новый расход",
  new_groups: "Новая группа",
  new_measurements: "Новая ед. изм.",
  new_order: "Новый заказ",
  new_owner_clinic: "Новый Брендинг",
  new_partner_clinic: "Новый партнер клиника",
  new_partner_doctor: "Новый партнер доктор",
  new_patient: "Добавить пациента",
  new_payment_type: "Новый тип оплаты",
  new_photo: "Добавить изображение",
  new_price: "Цена за одно количество",
  new_product: "Новый продукт",
  new_report: "Новый отчет",
  new_role: "Новая роль",
  new_room: "Новая комната",
  new_service: "Новая услуга",
  new_source: "Новый источник",
  new_specialities: "Новая специальность",
  new_status: "Новый статус",
  new_supplier: "Новый поставщик",
  new_tag: "Новый тег",
  new_time: " Новое время",
  new_type_document: "Новый тип документа",
  new_type_expence: "Новый тип расхода",
  new_user: "Новый пользователь",
  new_warehouse: "Новый склад",
  next: "Следующий",
  no: "Нет",
  normaCost: "Норма расходов",
  normaLaboratory: "Норма Лаборатория",
  not_full: "Не полные",
  not_paid: "Не оплачено",
  not_paid_invoices: "Не oплаченных счет-фактур",
  not_paid_of_all: "Ничего не оплачено",
  not_paid_sum: "Неоплаченная сумма",
  not_set: "Не указан",
  note: "Заметка",
  notes: "Заметки",
  npt_active: "Не активный",
  number: "Номер",
  number_aggrement: "Номер договора",
  number_card: "Номер амбулаторной карты",
  number_of_closed_conclusions_month: "Утвержденных заключений за месяц",
  number_of_orders_month: "Количество заказов за месяц",
  number_of_orders_today: "Количество заказов сегодня",
  number_of_orders_total: "Количество заказов общее",
  number_of_patients_month: "Количество пациентов за месяц",
  number_of_patients_today: "Количество пациентов за сегодня",
  number_of_patients_total: "Количество пациентов общее",
  number_of_patients_week: "Количество пациентов за неделю",
  number_of_written_conclusions_month: "Написанно заключений за месяц",
  nurse_office: "Кабинет медсестры",
  nurse_wrong_choice: "Медсестра ошиблась в выборе клеток",
  oked: "ОКЕД",
  okonx: "ОКОНХ",
  old: "Старый",
  on_close: "При закрытии",
  on_payment: "При оплате",
  once_price: 'Цена за единицу',
  onlis: "ОНЛИС",
  open: "Открыть",
  operation_canceled: 'Операция отменена',
  order: "Заказ",
  orderServiceTerm: "Заказы по датам",
  order_amount: "Сумма обслуживания",
  order_amput: "Сумма обслуживания",
  order_column: 'Создание полей',
  order_count: "Количество заказов",
  order_created_at: "Дата приема заказа",
  order_date: "Дата заказа",
  order_id: "ID заказа",
  order_not_found: "Заказ не найден",
  order_number: "Номер заказа",
  order_payment: "Заказ платежей",
  order_service: "Заказать услугу",
  order_updated_at: "Дата исполнения",
  orders: "Заказы",
  organization: "Организация",
  outgoing: "Исходящий",
  outpatient_card: "Амбулаторная карта",
  outsource: "Аутсорс",
  outsource_add: "Добавить аутсорс",
  outsource_party: "Партия аутсорса",
  outsourcing_files: "Файл аутсорса",
  overpaid: "Намного больше оплачено",
  overpaid_amount: "Лишняя сумма",
  own_doctor: "Наш доктор",
  owner_branch: 'Филиалы',
  owner_clinic: "Брендинг",
  package_quantity: 'Количество в упаковке',
  page_header: "Верхний колонтитул",
  paid: "Оплачено",
  paid_invoices: "Оплаченных счет-фактур",
  paid_partner_bonus: "Выплаченный бонус партнерам",
  paid_price: "Сумма оплаты",
  parent: "Родитель",
  parent_category: "Родительская категория",
  parent_service: "Родительские услуги",
  partner_amount: "Партнер сумма",
  partner_bonus: "Бонус партнеров",
  partner_clinic: "Партнер клиника",
  partner_clinic_full_logo: "Лого (полное)",
  partner_clinic_full_logo_active: "Лого (полное) активно",
  partner_clinic_mini_logo: "Лого (мини)",
  partner_clinic_mini_logo_active: "Лого (мини) активно",
  partner_clinics: "Партнер клиники",
  partner_doctor: "Партнер доктор",
  partners: "Партнеры",
  party_services: "Услуги партии",
  passport_number: "Номер паспорта",
  password: "Пароль",
  patient: "Пациент",
  patientResidenceReport: "Отчет по районам",
  patientServiceReport: "Отчет по услугам",
  patient_0: "Пациент",
  patient_age_reports: "Возраст отчеты",
  patient_ages: "Возраст пациентов",
  patient_balance: "Баланс пациента",
  patient_conclusions: "Заключение пациента",
  patient_histories: "История пациентов",
  patient_history: "История пациентов",
  patient_hospitalization: "Госпитализация пациента",
  patient_id: "ID пациента",
  patient_indicator: "Показатель пациента",
  patient_indicators: "Показатели пациента",
  patient_info: "Информация о пациенте",
  patient_name: "Имя пациента",
  patient_or_fio: "Пациент № или ФИО",
  patient_physiotherapy: "Физиотерапия пациента",
  patient_reject: "Пациент отказал/а",
  patient_report: "Все заключения пациента",
  patient_service: "Услуги пациента",
  patients: "Пациенты",
  patients2: "Пациенты",
  patients_count: "Количество пациентов",
  patients_income: "Общий приход в клинику",
  patronymic: "Отчество",
  pay: "Оплатить",
  pay_order: "Оплатить заказы",
  payable_sum: "Оплачиваемая сумма",
  payment: "Платеж",
  paymentBonusP: "Бонусные Платежи",
  payment_report_limit: "Лимит платежного отчета",
  payment_reports: "Отчёты платежей",
  payment_type: "Тип оплаты",
  payments: "Платежи",
  payments_for_expenses: "Платежи за расходы",
  payments_report: "Отчет платежей",
  percent: "% продажи",
  percent_: "% ",
  percentage: "Процент",
  period: "Период",
  phone: "Телефон",
  phone_number: "Номер телефона",
  phone_number_is_not_valid: "Этот номер телефона не соответствует",
  phone_numbers: "Номер телефоны",
  phone_two: "Телефон 2",
  photo: "Фотография(3x4 см)",
  phy_office: "Кабинет физиотерапии",
  physiotherapy: "Физиотерапия",
  pieces: "шт.",
  placeholder_time_date: "Выберите дату и время",
  please_choose_parent: "Пожалуйста, выберите родительскую категорию",
  please_choose_role: "Пожалуйста, выберите роль",
  please_enter_parol: "Пожалуйста, введите пароль",
  pockets: "Пакеты",
  position: "Должность",
  pp: "П.П.",
  preparing: "На подготовке",
  price: "Стоимость",
  price2: "Цена",
  price_aggrement: "Сумма договора",
  price_one_day_min_room_price: 'Минимальная сумма пакета койки за 1 день',
  price_one_day_with_room_price: 'Стоимость пакета за 1 день с койкой',
  price_one_day_without_room_price: 'Стоимость пакета за 1 день',
  price_per_day: "Цена за сутки",
  price_with_room_price: 'Стоимость пакета за {days} день с койкой',
  print: "Печать",
  print_audit: "Печатный аудит",
  print_center: "Центр печати",
  print_conclusion: "Распечатать заключение",
  print_double: "Печать 2x",
  print_form: "Печатная форма",
  print_hitory: "История печати",
  print_insurance: 'Медстаткарта',
  print_qr: 'Распечатка QR кода',
  printing: "Печать",
  procedural: "Процедурная",
  procedural_0: "Процедурная",
  procedural_list: "Процедурный лист",
  process: "Процесс",
  process_l: "Процессы Л.П.Д",
  processes: "Процессы",
  procurement: "Закупки",
  product: "Продукт",
  product_detail: "Детали продукта",
  products: "Продукты",
  profile: "Профиль",
  provision: "Условие",
  puls: "А Д",
  purchasing_products: "Закупка продукции",
  purpose_of_hospitalization: "Цель госпитализации",
  purpose_quantity: "Количество назначений",
  qty_come: "Кол. прихода",
  quantity: "Количество",
  quantity_short: "Кол-во",
  quarantine: "Карантин",
  quarterly: "За квартал",
  rate: "Курс",
  raw_producement: "Закупка сырья",
  reason: "Причина",
  receive: "Приход",
  receive_: "Принять",
  reception_visit: "Визит на прием",
  recipient: "Получатель",
  refresh: "Обновить",
  refund: "Возврат",
  region: "Регион",
  region_id: "ID региона",
  reject: "Отклонить",
  rejected: "Отклонено",
  remaind: "Остаток",
  remainder: "Остаток",
  remainder_reports: "Отчет процедурная",
  remainders: "Остатки",
  replacement: "Замена",
  report_clinics: "Отчет по клинике",
  report_doctors: "Отчет-врачи",
  report_insurance_company: "Отчёт по страховой фирме",
  report_partner_clinics: "Отчет по партнёрам клиникам",
  report_partner_doctors: "Отчет по партнёрам докторов",
  report_warehouse: "Отчёт по заказам",
  reports: "Отчеты",
  requirement: "Требование",
  requirements: "Требования",
  reset: "Сброс",
  resident_price: 'Цена для нерезидентов',
  resourcesText: "Ресурсы",
  responsible_name: "Представитель",
  return_material: "Возврат сырья",
  reverse: "Обратный",
  reverse_rate: "Обратный курс",
  rkp_nds: "РКП НДС",
  role: "Роль",
  role_name: "Имя роли",
  roles: "Роли",
  room: "Комната",
  rooms: "Комнаты",
  rooms_number: "Номер комнаты",
  running_title: "колонтитул",
  saldo: "Сальдо",
  sale_amount: "Сумма продажи",
  sale_percentage: "Процент продажи",
  sale_price: "Цена продажи",
  save: "Сохранить",
  save_and_close: "Сохранить и закрыть",
  save_and_create_new: "Сохранить и добавить новую",
  save_and_go: "Сохранить и продолжить",
  schedule: "Расписание",
  schedule_cabinet: "Расписание кабинета",
  score: " Счета",
  scores: "Счета",
  search: "Найти",
  search_0: "Поиск",
  search_doctor: "поиск врача",
  search_order: "Найти заказы",
  search_order0: "Найти заказ",
  search_template: "Найти шаблон",
  secondary_doctor: 'Направляющий врач',
  select_patient: "Выберите пациента",
  select_patient_or_find: "Выберите пациента слева или добавьте нового",
  select_time: "Выберите дату и время",
  send: "Отправить",
  sent: "Отправлено",
  serial_number: "Серийный номер",
  series_and_number: "Серия и номер",
  service: "Услуга",
  service_category: "Категория услуги",
  service_categoy: "Категория услуги",
  service_name: "Название услуги",
  service_type: "Тип услуги",
  service_type_id: "ID категории услуг",
  service_type_name: "Название типа услуги",
  service_types: "Типы услуг",
  services: "Услуги",
  services_count: "Кол-во услуг",
  set_header_footer: "Установка хэдера и футера",
  set_sign: "Установка подписи",
  set_transaction_amount: "Укажите сумму транзакции",
  settings: "Настройки",
  short_name: "Краткое наименование",
  show: "Показано",
  show_0: "Показать",
  show_audit: "Показать аудит",
  show_conclusions: "Показать заключение",
  show_drug: "Посмотреть лекарства",
  show_valid_price: "Укажите стоимость медикаментов",
  showing: "Просмотреть",
  sickNumber: "МКБ-код",
  sign_in: "Войти в систему",
  sku: "СКУ",
  slug: "Код",
  social: "Откуда узнали",
  source: "Источник",
  specialities: "Специальности",
  speciality: "Специальность",
  speciality_doctor: "специальность врача",
  startPeriod: "От числа",
  start_date: "от дата",
  start_input_find_service: "Начните вводить наименование или артикуль услуги",
  start_period: "От",
  stasionary_cashier: "Касса стационара",
  static_info: "Статистика",
  stationary: "Стационар",
  stationary_settings: "Настройки стационара",
  status: "Статусы",
  status_0: "Статус",
  status_cancel: "Отменён",
  status_closed: "Успешно",
  status_condition: "Статус условия",
  status_doctor: "У врача",
  status_doctor_free: "Заказы врачи",
  status_lab_nurse: "Забор анализов",
  status_laborant: "У лаборанта",
  status_laborant_free: "Заказы лаборатории",
  status_nurse: 'Забор анализов',
  status_payment: "На оплате",
  status_semi_created: "Ждет подтверждения",
  status_waiting_confirm: "Ждет подтверждения",
  status_waiting_paid: "Ждет оплаты",
  status_written: "Заключение написано",
  street: "Улица",
  subject_of_agreement: "Предмет договора (Услуги)",
  sum: "Сумма",
  sum_expence: "Сумма расхода",
  sum_receive: "Сумма прихода",
  summ: "сумма",
  suppliers: "Поставщики",
  suppliers_aggrement: "Договора поставщиков",
  surname: "Фамилия",
  symbol: "Символ",
  system_settings: "Настройки системные",
  tags: "Теги",
  tel_number: "Номер телефона",
  temperature: "Температура",
  templates: "Шаблоны",
  term: "Срок годности",
  term_aggrement: "Срок договора",
  terminal: "Терминал",
  test: "Тест",
  theme_setting: "Настройка темы",
  time: "Время",
  times: "Время",
  title: "заголовок",
  to_history: "к истории",
  to_time: "Время окончания",
  today_transactions: "Общая сумма за сегодня",
  top_services: "Топ услуг",
  total: "Итог",
  total_0: "Итог",
  total_amount: "Общая сумма",
  total_cost: "Общая стоимость",
  total_price: "Общая сумма",
  total_reports: "Общие отчеты",
  totals: "Итоги",
  totaly: "Общее",
  transactionScores: "Баланс",
  transactions: "Транзакции",
  transmitter: "Отправитель",
  type: "Тип",
  type_condition: "Вид состояния",
  type_documents: "Тип документа",
  type_expence: "Тип расхода",
  type_expences: "Типы расхода",
  type_of_injury: "Вид травмы",
  type_phone: "Тип телефона",
  type_receive: "Типы прихода",
  typebonus: "Тип Бонус",
  unnecessary_enclosure: "Не нужно заключение",
  until: "До",
  update: "Изменить",
  update_access_rights: "Изменить права доступа",
  update_branch: "Изменить отделение",
  update_bunk: "Изменить койку",
  update_category_service: "Изменить категорию услуги",
  update_citizenship: "Изменить гражданство",
  update_city: "Изменить город",
  update_class: "Изменить класс",
  update_condition: "Изменить состояние",
  update_conditions: "Изменить состояние",
  update_corps: "Изменить корпус",
  update_country: "Изменить страну",
  update_currency: "Изменить валюту",
  update_date: "Дата изменения",
  update_department_user: "Изменить пользователя отдела",
  update_departmentes: "Изменить отдел",
  update_discount: "Изменить скидка",
  update_doctor_sign: "Изменить подпись",
  update_expence: "Изменить расход",
  update_floor: "Изменить этаж",
  update_hospitalization: "Изменить госпитализацию",
  update_hospitalization_type: "Изменить тип госпитализации",
  update_movement: "Изменить перемещение",
  update_nationality: "Изменить национальность",
  update_new_patient: "Изменить нового пациента",
  update_organization: "Изменить организацию",
  update_partner_clinic: "Изменить партнера клиника",
  update_partner_doctor: "Изменить партнера доктора",
  update_patient: "Изменить пациента",
  update_patient_indicators: "Изменить показатели пациента",
  update_payment_type: "Изменить тип оплаты",
  update_product: "Изменить продукт",
  update_purpose_of_hospitalization: "Изменить цель госпитализации",
  update_region: " Изменить регион",
  update_report: "Изменить отчет",
  update_role: "Изменить роль",
  update_room: "Изменить комнату",
  update_service: "Изменить услугу",
  update_setting: "Изменить",
  update_source: "Изменить источник",
  update_specialities: "Изменить специальность",
  update_tags: "Изменить тег",
  update_teg: "Изменить тег",
  update_time: " Изменить время",
  update_type_document: "Изменить тип документа",
  update_type_expence: "Изменить тип расхода",
  update_type_of_injury: "Изменить вид травмы",
  used: "Используемый",
  used_cassettes: "Использованных кассет",
  used_glasses: "Использованных стекол",
  used_medicine: "Используемый процедурная",
  user: "Пользователь",
  username: "Владелец:",
  users: "Пользователи",
  valid: "Медикаменты",
  validation: "Ошибка",
  version: "Версия",
  vial_quantity: "Количество флаконов",
  view: "Вид",
  view_0: "Видеть",
  warehouse: "Склад",
  warehouse_movement: "Перемещение складов",
  warehouses: "Склады",
  warehouses_0: "Склады",
  warning: "Предупреждение",
  web_site: "Веб-сайт",
  website_name: "Укажите название веб-сайта",
  week: "Неделя",
  weekly: "За неделю",
  weight: "Вес",
  whence: "Откуда",
  where_to: "Куда",
  without_amount: "Без расчета",
  without_calculation: "Договора №",
  woman: "Женщина",
  women: "Женщины",
  work_schedule: "График работы",
  write_conclusions: "Написать заключение",
  write_of_from: "Дата списания от",
  write_off: "Списать",
  write_off_date: "Дата списания",
  write_off_items: "Продукты для списания",
  write_off_products: "Списание продуктов",
  write_off_quantity: "Количество для списания",
  write_offs: "Списание",
  written_conclusions: "Написано заключений",
  year: "Год",
  yearly: "За год",
  yes: "Да",
  yes_doctor: "Связь с доктором",
}
