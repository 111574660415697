import { i18n } from '@/utils/i18n';

export const columns = {
  id: {
    show: true,
    title: '№',
    sortable: true,
    column: 'id'
  },
  date: {
    show: true,
    title: i18n.t('message.write_off_date'),
    sortable: true,
    column: 'name'
  },
  sender: {
    show: true,
    title: i18n.t('message.transmitter') ,
    sortable: true,
    column: 'sender_id'
  },
  // receiver: {
  //   show: true,
  //   title: i18n.t('message.recipient') ,
  //   sortable: true,
  //   column: 'receiver_id'
  // },
  warehouse: {
    show: true,
    title: i18n.t('message.warehouse'),
    sortable: true,
    column: 'warehouse_id'
  },
  products: {
    show: true,
    title: i18n.t('message.products'),
    sortable: true,
    column: 'items'
  },
  created_at: {
      show: true,
      title: i18n.t('message.date'),
      sortable: true,
      column: 'created_at'
  },
  updated_at: {
      show: false,
      title: i18n.t('message.update_date'),
      sortable: true,
      column: 'updated_at'
  },
  settings: {
      show: true,
      title: i18n.t('message.settings'),
      sortable: false,
      column: 'settings'
  }
};